export var AppFormGroupType;
(function (AppFormGroupType) {
    AppFormGroupType["CHAIRMAN"] = "chairman";
})(AppFormGroupType || (AppFormGroupType = {}));
;
export var AppFormAttachmentsType;
(function (AppFormAttachmentsType) {
    AppFormAttachmentsType["TYPED"] = "TYPED";
    AppFormAttachmentsType["UNTYPED"] = "UNTYPED";
})(AppFormAttachmentsType || (AppFormAttachmentsType = {}));
export const buildDefaultAppForm = (dataFields) => {
    const result = {
        conditions: [
            'Tiekunta on järjestäytynyt',
            `Yksityistie on merkitty <a target="_blank" href="https://www.maanmittauslaitos.fi/huoneistot-ja-kiinteistot/asiantuntevalle-kayttajalle/yksityistierekisteri">Maanmittauslaitoksen yksityistierekisteriin</a>.`,
            `Yksityistie on merkitty <a target="_blank" href="https://vayla.fi/vaylista/aineistot/digiroad">Väyläviraston tie- ja katuverkon tietojärjestelmään (Digiroad)</a>.`,
        ],
        groups: [
            {
                title: 'Perustiedot',
                fields: [],
            },
            {
                title: 'Yhteyshenkilö',
                help: 'Jos sähköistä asiointia hoitaa hoitokunnan puheenjohtajan tai toimitsijamiehen sijasta eri henkilö,'
                    + ' voit ilmoittaa tässä muun yhteyshenkilön muuttamalla henkilön roolia. PJ/TM tiedot kysytään tällöin erikseen.',
                fields: [],
            },
            {
                title: 'Hoitokunnan puheenjohtaja / toimitsijamies',
                help: 'Ole hyvä ja anna myös hoitokunnan puheenjohtajan tai toimitsijamiehen tiedot.',
                type: AppFormGroupType.CHAIRMAN,
                fields: [],
            },
            {
                title: 'Hakemustiedot',
                fields: [],
            }
        ],
        hasAdditionalInformation: true,
        hasEdecision: true,
        attachments: undefined,
    };
    for (const dataField of dataFields) {
        if (dataField.keyAppS) {
            let targetGroupIdx = -1;
            if (dataField.keyAppS.startsWith('contactPerson')) {
                targetGroupIdx = 1;
            }
            else if (dataField.keyAppS.startsWith('chairman')) {
                targetGroupIdx = 2;
            }
            else {
                targetGroupIdx = dataField.keyPrc ? 0 : 3;
            }
            if (targetGroupIdx !== -1) {
                result.groups[targetGroupIdx].fields.push({
                    dataField,
                    isRequired: true,
                });
            }
        }
    }
    return result;
};
