import angular from 'angular';
import ngMessages from 'angular-messages';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import moment from 'moment';
import ngTranslate from 'angular-translate';
import 'angular-translate-loader-static-files';
import TkrSpaCommon from '../common/tkr-spa';
import TkrAppConfig from '../common/TkrAppConfig';
import { IbanService, IbanValidator } from '../common/ma-iban';
import { SmartFloat, SmartInteger } from '../common/ma-smartfloat-1.0';
import ppResources from './prcportal-resource-modules';
import { TkrPersonService } from '../common/tkr-commonservices';
import { DataType, ApplicationState, ApplicationSubmitType } from '../common/tkr-constants';
import { AppFormGroupType } from '../common/ApplicationPeriod';
import tkrPpCommon from './common/0-pp-common-module';
import { configureTranslateProvider } from './translation-helpers';
export default angular.module('ng-tkr-app-generic', [
    TkrSpaCommon,
    TkrAppConfig,
    ngMessages,
    ngTranslate,
    TkrPersonService,
    IbanValidator,
    IbanService,
    SmartFloat,
    SmartInteger,
    ppResources,
    tkrPpCommon,
])
    .config(config)
    .config(configureTranslateProvider)
    .controller('SubsidyApplicationController', SubsidyApplicationController)
    .run(run)
    .name;
run.$inject = ['$transitions', 'mDbg'];
function run($transitions, mDbg) {
    mDbg.setup($transitions);
}
config.$inject = ['$stateProvider', '$urlRouterProvider'];
function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/err');
    $stateProvider
        .state('err', {
        url: '/err',
        template: '<p class="h4 text-danger">Sisäinen virhe. Ota yhteyttä helpdesk@matriset.fi.</p>'
    })
        .state('root', {
        url: '',
        template: '',
        controller: 'AppRedirCtrl',
        controllerAs: 'vm'
    })
        .state('lomake', {
        url: '/:applicationId',
        template: require('./generic-app.tpl.html'),
        controller: 'SubsidyApplicationController',
        controllerAs: 'vm',
        resolve: {
            app: ['PortalSubsidyAppService', '$stateParams', function (PortalSubsidyAppService, $stateParams) {
                    return PortalSubsidyAppService.get({ appId: $stateParams.applicationId }).$promise;
                }],
            appPeriod: ['PortalAppPeriodService', 'app', function (PortalAppPeriodService, app) {
                    return PortalAppPeriodService.get({ periodId: app.applicationPeriodId }).$promise;
                }],
            session: ['SessionDataService', function (SessionDataService) {
                    return SessionDataService.get().$promise;
                }]
        }
    })
        .state('jatetty', {
        url: '/:applicationId/jatetty',
        template: require('./generic-app-submitted.tpl.html'),
        controller: 'AppSubmittedController',
        controllerAs: 'vm'
    });
}
SubsidyApplicationController.$inject = [
    'app',
    'TkrPersonService',
    '$state',
    'appPeriod',
    '$sce',
    '$templateCache',
    '$translate',
    'session',
    'TkrConfig',
];
function SubsidyApplicationController(app, TkrPersonService, $state, appPeriod, $sce, $templateCache, $translate, session, TkrConfig) {
    var _a;
    const vm = this;
    vm.appPeriod = appPeriod;
    vm.paperAppAllowed = vm.appPeriod.paperAppEnabled && !!session.permissions.PAPERAPP;
    vm.appYear = lodashGet(appPeriod, 'td.wnum.vuosi', new Date().getFullYear());
    vm.prevYear = vm.appYear - 1;
    vm.loppuAika = moment(appPeriod.end).local().format('D.M.YYYY HH:mm:ss');
    vm.translationVars = {
        loppuAika: vm.loppuAika
    };
    const preprocessApp = (app) => {
        if (!app.chairman) {
            app.chairman = {};
        }
        if (!app.contactPerson) {
            app.contactPerson = {};
        }
        if (!app.td) {
            app.td = {};
        }
        if (!app.td.num) {
            app.td.num = {};
        }
        if (!app.td.bools) {
            app.td.bools = {};
        }
        app.applicationTitle = appPeriod.name;
        if (appPeriod.appFormDefinition.eDecisionMandatory) {
            app.eDecision = true;
        }
        if (vm.paperAppAllowed && app.submitType === ApplicationSubmitType.PAPER && app.submitTime) {
            vm.paperAppDate = moment(app.submitTime).format('YYYY-MM-DD');
        }
        else {
            vm.paperAppDate = undefined;
            app.submitType = ApplicationSubmitType.ELECTRONIC;
        }
        return app;
    };
    vm.app = preprocessApp(app);
    vm.trustedHtml = function (html) {
        const translated = $translate.instant(html, {}, undefined, undefined, null);
        return $sce.trustAsHtml(translated);
    };
    vm.isEligible = function () {
        var _a, _b, _c, _d, _e;
        const valueFromTd = (_b = (_a = vm.app.td) === null || _a === void 0 ? void 0 : _a.bools) === null || _b === void 0 ? void 0 : _b.isEligible;
        if (typeof valueFromTd === "boolean") {
            return valueFromTd;
        }
        if (((_e = (_d = (_c = vm.appPeriod) === null || _c === void 0 ? void 0 : _c.appFormDefinition) === null || _d === void 0 ? void 0 : _d.conditions) === null || _e === void 0 ? void 0 : _e.length) === 0) {
            return true;
        }
        return undefined;
    };
    const isChairmanRequired = () => {
        return true;
    };
    const isSeparateChairmanRequired = () => {
        var _a, _b;
        if (!isChairmanRequired()) {
            return false;
        }
        return !TkrPersonService.isValidChairmanRole((_b = (_a = vm === null || vm === void 0 ? void 0 : vm.app) === null || _a === void 0 ? void 0 : _a.contactPerson) === null || _b === void 0 ? void 0 : _b.role);
    };
    vm.save = function () {
        if (!isChairmanRequired()) {
            vm.app.chairman = null;
        }
        if (!isSeparateChairmanRequired()) {
            vm.app.chairman = vm.app.contactPerson;
        }
        if (!vm.app.eDecision) {
            vm.app.eDecisionEmail = undefined;
        }
        vm.app.applicationState = ApplicationState.SUBMITTED;
        vm.app.$save({
            lang: $translate.use(),
            paperDate: vm.paperAppDate,
        }).then(function onSuccess(data) {
            $state.go('jatetty', { applicationId: data.id });
        });
    };
    vm.saveDraft = () => {
        vm.app.applicationState = ApplicationState.DRAFT;
        vm.app.$save().then(function (data) {
            vm.draftSaved = true;
        });
    };
    vm.getPropParentRef = function (obj, prop) {
        const parts = prop.split('.');
        if (parts.length > 1) {
            const t = parts.shift();
            const rem = parts.join('.');
            if (!obj.hasOwnProperty(t) || obj[t] === null || obj[t] === undefined) {
                obj[t] = {};
            }
            return vm.getPropParentRef(obj[t], rem);
        }
        else {
            return obj;
        }
    };
    const fieldHtml = (fld) => {
        var _a, _b, _c;
        let template;
        const df = fld.dataField;
        switch (df.type) {
            case DataType.STRING:
                if (df.rows && df.rows > 1) {
                    template = `<app-edit-textarea input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" ng-required="fld.isRequired" m-help="{{::fld.help}}" rows="${df.rows}"></app-edit-textarea>`;
                }
                else {
                    template = `<app-edit input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" ng-required="fld.isRequired" m-help="{{::fld.help}}" m-read-only="fld.isReadOnly"></app-edit>`;
                }
                break;
            case DataType.NUMBER:
            case DataType.INTEGER:
                template = `<app-edit input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" m-type="${df.decimals ? 'number' : 'integer'}" m-short input-addon="{{::fld.dataField.unit}}"
                    m-help="{{::fld.help}}" ng-model="obj[key]" ng-required="fld.isRequired" m-read-only="fld.isReadOnly"></app-edit>`;
                break;
            case DataType.EMAIL_ADDRESS:
                template = `<app-edit input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" m-type="email"
                    m-help="{{::fld.help}}" ng-model="obj[key]" ng-required="fld.isRequired" m-read-only="fld.isReadOnly"></app-edit>`;
                break;
            case DataType.IBAN:
                template = `<app-edit-iban input-label="{{::(fld.title || fld.dataField.title || fld.dataField.name) | translate}}" ng-model="obj[key]" bic-field="vm.app.bic"
                    ng-required="fld.isRequired" m-help="{{::fld.help | translate}}">/<app-edit-iban>`;
                break;
            case DataType.PERSON_ROLE:
                template = `<app-edit-role input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" ng-required="fld.isRequired"></app-edit-role>`;
                break;
            case DataType.CHAIRMAN_ROLE:
                template = `<app-edit-role input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" person-type="chairman" ng-required="fld.isRequired"></app-edit-role>`;
                break;
            case DataType.ADDRESS_NAMES:
                if (!lodashGet(app, df.keyAppS)) {
                    lodashSet(app, df.keyAppS, []);
                }
                template = `<app-edit-address-names input-label="{{::(fld.title || fld.dataField.title || fld.dataField.name) | translate}}" ng-model="obj[key]" ng-required="fld.isRequired"
                    m-help="{{::fld.help | translate}}" m-read-only="fld.isReadOnly" default-town="${TkrConfig.defaultAddressNameTown || ''}"></app-edit-address-names>`;
                break;
            case DataType.BOOL:
                template = `<app-edit-bool-radio input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" m-help="{{::fld.help}}"></app-edit-bool-radio>`;
                break;
            case DataType.DATE:
                template = `<app-edit-date input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" m-help="{{::fld.help}}" ng-required="fld.isRequired"></app-edit-date>`;
                break;
            case DataType.FORMULA_NUMBER:
                template = `<app-formula-number title="{{::fld.title || fld.dataField.title || fld.dataField.name}}" obj="vm.app" formula="{{::fld.dataField.formula}}" unit="{{::fld.dataField.unit}}" decimals="{{::fld.dataField.decimals}}" m-help="{{::fld.help}}" ng-model="obj[key]"></app-formula-number>`;
                break;
            case DataType.WEBSITE_ADDRESS:
                template = `<app-edit-website-address input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" ng-required="fld.isRequired" m-help="{{::fld.help}}"></app-edit-website-address>`;
                break;
            case DataType.OPTIONS_LIST:
                if ((_a = df.optionsList) === null || _a === void 0 ? void 0 : _a.multiSelect) {
                    template = `<app-edit-options-multiselect options-list="fld.dataField.optionsList" input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" ng-required="fld.isRequired" m-help="{{::fld.help}}"></app-edit-options-multiselect>`;
                }
                else {
                    template = `<app-edit-options-radiogroup options-list="fld.dataField.optionsList" input-label="{{::fld.title || fld.dataField.title || fld.dataField.name}}" ng-model="obj[key]" ng-required="fld.isRequired" m-help="{{::fld.help}}"></app-edit-options-radiogroup>`;
                }
                break;
            case DataType.COST_TABLE:
                if (!lodashGet(app, df.keyAppS)) {
                    lodashSet(app, df.keyAppS, JSON.parse(JSON.stringify(df.costTable)));
                }
                if (!lodashGet(app, (_b = df.keyAppS) === null || _b === void 0 ? void 0 : _b.concat('.rows'))) {
                    lodashSet(app, (_c = df.keyAppS) === null || _c === void 0 ? void 0 : _c.concat('.rows'), []);
                }
                template = `<app-edit-cost-table ng-model="obj[key]" help-bottom="{{::fld.help}}" currency-unit="{{::fld.dataField.unit}}" currency-decimals="{{::fld.dataField.decimals}}"></app-edit-cost-table>`;
                break;
            default:
                template = '<pre>{{fld | json}}</pre>';
                break;
        }
        return template;
    };
    vm.buildFieldId = (group, field) => {
        var _a, _b, _c;
        return `f${(_b = (_a = appPeriod.appFormDefinition) === null || _a === void 0 ? void 0 : _a.groups) === null || _b === void 0 ? void 0 : _b.indexOf(group)}_${(_c = group === null || group === void 0 ? void 0 : group.fields) === null || _c === void 0 ? void 0 : _c.indexOf(field)}`;
    };
    for (const grp of ((_a = appPeriod.appFormDefinition) === null || _a === void 0 ? void 0 : _a.groups) || []) {
        for (const fld of grp.fields || []) {
            if (!fld.isHidden) {
                $templateCache.put(vm.buildFieldId(grp, fld), fieldHtml(fld));
            }
        }
    }
    vm.groupVisible = (group) => {
        if (group.type === AppFormGroupType.CHAIRMAN) {
            return isSeparateChairmanRequired();
        }
        return true;
    };
}
